//import './Playlists.css';
//import axios from 'axios';
import React from 'react';

class Artists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {artist_list: null};
  }
  componentDidMount() {
    if(this.props.loadedArtists == null) {
      let artist_url = this.props.url + '/all.php';
      if(process.env.NODE_ENV == 'development') {
        artist_url = 'http://localhost:13241/all-test.php';
      }
      var formData = new FormData();
      formData.append('akey', this.props.akey);
      const requestOptions = {
          method: 'post',
          body: formData
      };
      fetch(artist_url, requestOptions)
          .then(res => res.json())
          .then((data) => {
            let all_artists = Array();
            for (var i = 0; i < data.length; i++) {
              all_artists = all_artists.concat(data[i].list);
              //console.log(data[i].list);
            }
            //console .log(data);
            this.setState({artist_list: all_artists});
            this.props.setArtists(all_artists);
          })
          .catch(err => console.error(err));
    }
    else {
      this.setState({artist_list: this.props.loadedArtists});
    }


  }

  getSongArtist = (filename) => {
    let name1 = filename.split('/');
    name1 = name1[name1.length-1].split('.');
    return name1[0];
  }

  getSongGenre = (filename) => {
    let name1 = filename.split('/');
    //name1 = name1[name1.length-1].split('.');
    return name1[0];
  }


  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  SwitchPlayList = (playlist) => {
    //console.log(playlist);
    if(this.props.random == true) {
      this.shuffleArray(playlist.items);
      //console.log("SuFFLELEFLELFE");
    }
    //console.log(playlist);
    this.props.updatePlaylist(playlist);
  }

  AddPlayList = (playlist) => {
    //console.log(playlist);
    //console.log(this.props.playlist);
    let new_items = playlist.items;

    if(this.props.playlist != null) {
      new_items = this.props.playlist.items.concat(playlist.items);
    }

    let new_playlist = {
      name: 'Custom list',
      items: new_items
    };

    if(this.props.random == true) {
      this.shuffleArray(new_playlist.items);
      //console.log("SuFFLELEFLELFE");
    }
    console.log(new_playlist);
    this.props.updatePlaylist(new_playlist, true);
  }

  ActivePlaylist = (item) => {
    console.log("----- activatePlaylist");
    console.log(this.props.playlist);
    console.log(item);
    console.log("----- activatePlaylist");
    if(typeof item.name == 'string' && this.props.playlist != null) {
      if(item.name == this.props.playlist.name) {
        return 'active';
      }
    }

  }

  buildSongList = (list) => {

  }

  render() {

    let renderData = "Loading...";

    if(this.state.artist_list != null) {

      console.log(this.state.artist_list);
      renderData = "results";
      renderData = this.state.artist_list.map((item) =>
        <div className={'artist song-list-item listitem'}>
          <div className="listitem-inner">
            <div className="listitem-buttons">
              <div className="listitem-button" onClick={() => this.SwitchPlayList(item)}>
                <i class="fa-solid fa-play"></i>
              </div>
              <div className="listitem-button" onClick={() => this.AddPlayList(item)}>
                <i class="fa-solid fa-add"></i>
              </div>
              <div className="listitem-button">
                <i class="fa-solid fa-caret-down"></i>
              </div>
            </div>
            <div className="listitem-name">
              {this.getSongArtist(item.name)}
              <span>&nbsp;({item.items.length})</span>
            </div>
            <div className="listitem-meta">
              <span className="song-genre">{this.getSongGenre(item.name)}</span>
            </div>
          </div>
        </div>
      );
    }
    else {
      //console.log('state is null');


    }

    return (
      <div className="artists">
        { renderData }
      </div>
    );
  }

}

export default Artists;
