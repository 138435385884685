import './Playlists.css';
import axios from 'axios';
import React from 'react';
import {Playlist} from './Playlist';

class Playlists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {list: null};
  }
  componentDidMount() {
    var formData = new FormData();
    formData.append('akey', this.props.akey);
    const requestOptions = {
        method: 'post',
        body: formData
    };
    fetch(this.props.url + '/playlists.php', requestOptions)
        .then(res => res.json())
        .then(data => this.setState({list: data}))
        .catch(err => console.error(err));

  }

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  SwitchPlayList = (playlist) => {
    //console.log(playlist);
    if(this.props.random == true) {
      this.shuffleArray(playlist.items);
      //console.log("SuFFLELEFLELFE");
    }
    //console.log(playlist);
    this.props.updatePlaylist(playlist);
  }

  ActivePlaylist = (item) => {
    console.log("----- activatePlaylist");
    console.log(this.props.playlist);
    console.log(item);
    console.log("----- activatePlaylist");
    if(typeof item.name == 'string' && this.props.playlist != null) {
      if(item.name == this.props.playlist.name) {
        return 'active';
      }
    }

  }

  render() {

    let renderData = "Loading...";

    if(this.state.list != null) {
      //console.log(this.state.list);
      renderData = "results";
      renderData = this.state.list.list.map((item) =>
        <div onClick={() => this.SwitchPlayList(item)} className={'playlist '+this.ActivePlaylist(item)}><div className="playlist-inner"><img className="playlist-img" src={'https://server64.net/liststhumbs/'+item.name+'.jpg'} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://server64.net/liststhumbs/default.jpg";
  }} onerror="this.src='https://server64.net/liststhumbs/default.jpg';" /><div className="playlist-name">{item.name}</div><div className="playlist-count">{item.items.length}</div><div className="clear"></div></div></div>
      );
    }
    else {
      //console.log('state is null');
    }

    return (
      <div className="playlists">
        { renderData }
      </div>
    );
  }

}

export default Playlists;
