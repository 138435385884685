import './Player.css';
import React from 'react';

class Player extends React.Component {

  constructor(props) {
    super(props);
  }
  componentDidMount() {

    // fetch('http://localhost:13241/playlists.php')
    //     .then(res => res.json())
    //     .then(data => this.setState({list: data}))
    //     .catch(err => console.error(err));
    if(this.props.playlist != null) {

      window.requestAnimationFrame(function() {
        //document.getElementById('audio-player').play();
      });
    }

  }

  PlayOnLoad = () => {
    document.getElementById('audio-player').play();
  }

  componentDidUpdate() {
    if(this.props.playlist != null) {
      let testF = this.props.changeSong;
      let pauseF = this.props.pauseSong;
      let playF = this.props.playSong;
      let playB = this.props.playing;
      let playL = this.PlayOnLoad;
      window.requestAnimationFrame(function() {
        if (playB == true) {
          document.getElementById('audio-player').play();
        }

        document.getElementById('audio-player').addEventListener('ended', testF);
        document.getElementById('audio-player').addEventListener('pause', pauseF);
        document.getElementById('audio-player').addEventListener('play', playF);
        document.getElementById('audio-player').addEventListener('canplaythrough', playL);

      });

      if ("mediaSession" in navigator) {
        let metaD = this.props.cleanFileName(this.props.playlist.items[this.props.index]);
        let metaA = metaD.split(" - ");
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: metaA[1],
          artist: metaA[0],
          album: 'Music playing'
        });
      }

    }

  }



  SwitchPlayList(playlist) {
    console.log(playlist);
  }

  render() {

    let renderData =  (
      <>
      <div className="player-title">Select a playlist</div>
      </>
    );

    if(this.props.playlist != null) {
      console.log(this.props.playlist);
      renderData = (
        <>
        <div className="player-title">Playing: <strong>{this.props.playlist.name}</strong></div>
        <div>Song {this.props.index+1} of {this.props.playlist.items.length} ({this.props.cleanFileName(this.props.playlist.items[this.props.index])})</div>
        </>
      );

      return (
        <div className="player"><div className="player-inner">
          { renderData }
          <table className="player-table"><tr>
            <td style={{'width':'50px'}}><button onClick={() => this.props.changeSong(-1)} className="next-button" >&lt;</button></td>
            <td><audio style={{'width':'100%'}} id="audio-player" controls src={this.props.url + this.props.playlist.items[this.props.index]} /></td>
            <td style={{'width':'50px', 'textAlign':'right'}}><button onClick={() => this.props.changeSong(1)} className="next-button" >&gt;</button></td>
          </tr></table>

        </div></div>
      );

    }
    else {
      console.log('state is null');
      return (
        <div className="player"><div className="player-inner">
          { renderData }
        </div></div>
      );
    }


  }

}

export default Player;
