//import './Playlist.css';

function Playlist() {
  return (
    <div className="Playlist">

    </div>
  );
}

export default Playlist;
