import React from 'react';
import './App.css';
import Playlists from './Playlists';
import Player from './Player';
import Artists from './Artists';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

const DragHandle = sortableHandle(() => <div className="listitem-button"><i class="fa-solid fa-sort"></i></div>);

const SortableItem = sortableElement(({children, activeClass}) => {
  //console.log(...this.props);
  return <li>
  <div className={"listitem "+activeClass}><div className="listitem-inner">
    <div className="listitem-buttons">
      <DragHandle />
    </div>
    {children}
  </div></div>
  </li>;
});

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});

class App extends React.Component {
  constructor(props) {
    super(props);
    let testUrl = '';
    if(process.env.NODE_ENV == 'development') {
      testUrl = 'http://localhost:13241'; // local xampp server
      testUrl = 'https://server64.net'; // test with live server
    }
    this.state = {
      akey: this.props.dataFromPage.akey,
      playlist: null,
      section: 'playlists',
      index: 0,
      random: true,
      url: testUrl,
      playing: true,
      artists: null,
      playlists:null
    };
    console.log("------------------");
    console.log(process.env.NODE_ENV);
    console.log(this.state);
    console.log("------------------");
  }
  // componentDidMount() {
  //   const audio1 = document.createElement('audio');
  //     audio1.src = 'http://localhost:13241/file.php?file=Electronic%2FArchis.Speed+Boat+Theme.mp3';
  // }

  emptyCache = () => {
    if('caches' in window){
      caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true);
      }
  }

  setArtists = (load_artists) => {
    this.setState({
      artists: load_artists
    });
  }

  handleChangePlaylist = (data, keep_index = false) => {
    if(keep_index == false || this.state.playlist == null) {
      this.setState({
        playlist: data,
        index: 0
      });
    }
    else {
      let song_name = this.state.playlist.items[this.state.index];
      let new_index = 0;
      for (var i = 0; i < data.items.length; i++) {
        if(data.items[i] == song_name) {
          new_index = i;
        }
      }
      this.setState({
        playlist: data,
        index: new_index
      });
    }

  }

  handleChangeRandom = () => {
    if(this.state.random == true) {
      this.setState({
        random: false
      });
    }
    else {
      this.setState({
        random: true
      });
    }

    console.log("changed random: " + this.state.random);
  }

  cleanFileName = (filePath) => {
    let newName1 = decodeURI(filePath).split('=');
    console.log(newName1);
    newName1 = newName1[1].replace('.mp3', '');
    newName1 = newName1.split('%2F');
    return newName1[newName1.length-1].replace('.', ' - ').replaceAll('+', ' ').replaceAll('%26', '&').replaceAll('%2C', ',');
  }

  handleChangeSection = (section) => {
    console.log(section);
    this.setState({
      section: section
    });
  }

  handlePauseSong = () => {
    this.setState({
      playing: false
    });
    console.log('playing set false');
  }
  handlePlaySong = () => {
    this.setState({
      playing: true
    });
    console.log('playing set true');
  }

  handlePlaySongByIndex = (ind) => {
    this.setState({
      index: ind
    });
  }

  handleRemoveSongByIndex = (ind) => {
    let original_index = this.state.index;

    let new_playlist_items = this.state.playlist.items;
    new_playlist_items.splice(ind, 1);
    let new_playlist = {
      name: 'Custom list',
      items: new_playlist_items
    };

    this.handleChangePlaylist(new_playlist, true);

    if(original_index == ind) {
      //this.handleChangeSong(1);
    }
    if(ind < original_index) {
      this.setState({
        index: original_index-1
      });
    }
  }

  handleChangeSong = (num) => {
    document.getElementById('audio-player').pause();
    console.log("Changing song");
    if(num == -1) {
      if(this.state.index == 0) {
        this.setState({
          index: this.state.playlist.items.length - 1
        });
      }
      else {
        this.setState({
          index: (this.state.index - 1)
        });
      }
    }
    else {
      if(this.state.index < this.state.playlist.items.length-1) {
        this.setState({
          index: (this.state.index + 1)
        });
      }
      else {
        this.setState({
          index: 0
        });
      }
    }

    //document.getElementById('audio-player').play();
  }

  matchPlayingIndex = (playing) => {
    if(playing == this.state.index) {
      return 'active';
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    console.log(oldIndex);
    console.log(newIndex);
    let new_playlist_order = arrayMoveImmutable(this.state.playlist.items, oldIndex, newIndex);
    let new_playlist = {
      name: 'Custom list',
      items: new_playlist_order
    };
    this.handleChangePlaylist(new_playlist, true);
    /*
    this.setState(({items}) => ({
      items: arrayMoveImmutable(items, oldIndex, newIndex),
    }));
    */
  };

  render() {
    let mainSection = "Loading";
    if(this.state.section == "playlists") {
      mainSection = (
       <>
       <div className="playlists-wrapper">
       <Playlists updatePlaylist={this.handleChangePlaylist} url={this.state.url} random={this.state.random} playlist={this.state.playlist} akey={this.state.akey}/>
       </div>
       </>
     );
    }
    if(this.state.section == "artists") {
      mainSection = (
       <>
       <div className="artists-wrapper">
        <Artists updatePlaylist={this.handleChangePlaylist} url={this.state.url} random={this.state.random} playlist={this.state.playlist} setArtists={this.setArtists} loadedArtists={this.state.artists} akey={this.state.akey} />
       </div>
       </>
     );
    }
    if(this.state.section == "playing") {
      let playingList = "Nothing playing";
      if(this.state.playlist != null) {
        let playingListInner = this.state.playlist.items.map((item, key) =>
        <SortableItem key={`item-${item}`} index={key} activeClass={this.matchPlayingIndex(key)}>


            <div className="listitem-buttons">
              <div className="listitem-button" onClick={() => this.handlePlaySongByIndex(key)}>
                <i class="fa-solid fa-play"></i>
              </div>
            </div>
            <div className="listitem-name">
              {this.cleanFileName(item)}
            </div>
            <div className="listitem-meta">
              <div className="listitem-button" onClick={() => this.handleRemoveSongByIndex(key)}>
                <i class="fa-solid fa-minus"></i>
              </div>
            </div>


        </SortableItem>
        );
        playingList = <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>{playingListInner}</SortableContainer>;
      }

      mainSection = (
       <>
       <div className="playing-wrapper">
        {playingList}
       </div>
       </>
     );
    }

    return (
      <div className="App">
        <header className="player-header">
          <div className="header-inner">
            <button onClick={this.handleChangeRandom} className={'button-'+this.state.random.toString()} >Random</button>
          </div>
        </header>
        <div className={'section-tabs section-'+this.state.section}>
          <div className="tab tab-playlists" onClick={() => this.handleChangeSection('playlists')}>Playlists</div>
          <div className="tab tab-artists" onClick={() => this.handleChangeSection('artists')}>Artists</div>
          <div className="tab tab-playing" onClick={() => this.handleChangeSection('playing')}>Playing</div>
        </div>
        <div className="main-section">
          {mainSection}
        </div>
        <div className="player-wrapper">
        <Player playlist={this.state.playlist} random={this.state.random} index={this.state.index} url={this.state.url} changeSong={this.handleChangeSong} pauseSong={this.handlePauseSong} playSong={this.handlePlaySong} playing={this.state.playing} cleanFileName={this.cleanFileName} akey={this.state.akey}/>
        </div>
      </div>
    );
  }
}

export default App;
